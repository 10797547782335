import React from "react";
import "../styles/IndexingTerms.css";
import { Link } from "react-scroll";

function IndexingTerms() {
  return (
    <div id="IndexingTerms">
      <div className="indexingT">
        <ul>
          <li>
            <Link to="tc1" spy={true} smooth={true}>
              TIOfriends Terms & Conditions
            </Link>
          </li>
          <li>
            <Link to="tc2" spy={true} smooth={true}>
              REFERRAL FEES FOR FIRST LEVEL INTRODUCERS
            </Link>
          </li>
          <li>
            <Link to="tc3" spy={true} smooth={true}>
              Bronze level:
            </Link>
          </li>
          <li>
            <Link to="tc4" spy={true} smooth={true}>
              Silver level:
            </Link>
          </li>
          <li>
            <Link to="tc5" spy={true} smooth={true}>
              Gold level:
            </Link>
          </li>
          <li>
            <Link to="tc6" spy={true} smooth={true}>
              Platinum level:
            </Link>
          </li>
          <li>
            <Link to="tc7" spy={true} smooth={true}>
              REFERRAL FEES FOR ALL LEVELS
            </Link>
          </li>
          <li>
            <Link to="tc8" spy={true} smooth={true}>
              APP FUNCTIONALITY
            </Link>
          </li>
          <li>
            <Link to="tc9" spy={true} smooth={true}>
              ELIGIBILITY
            </Link>
          </li>
          <li>
            <Link to="tc10" spy={true} smooth={true}>
              GENERAL DECORUM IN PROMOTION OF FINANCIAL PRODUCTS
            </Link>
          </li>
          <li>
            <Link to="tc11" spy={true} smooth={true}>
              PAYMENTS & BALANCES
            </Link>
          </li>
          <li>
            <Link to="tc12" spy={true} smooth={true}>
              ABUSIVE BEHAVIOURS
            </Link>
          </li>
          <li>
            <Link to="tc13" spy={true} smooth={true}>
              GENERAL TERMS & DISCLOSURE
            </Link>
          </li>
        </ul>
      </div>
    </div>
  );
}

export default IndexingTerms;
