import React, { useEffect } from "react";
import "react-responsive-carousel/lib/styles/carousel.min.css"; // requires a loader
import { Carousel } from "react-responsive-carousel";
import { isMobile } from "react-device-detect";
import { Link } from "react-scroll";
import "../styles/Steps.css";
import { step1, step2, step4, step5 } from "../config/images";

function Steps() {
  // const [isMobile, setIsMobile] = useState(false);

  useEffect(() => {}, []);
  return (
    <div id="steps">
      <div className="container">
        <Carousel
          axis="horizontal"
          infiniteLoop={false}
          centerMode={false}
          showArrows={!isMobile}
          showStatus={false}
          showThumbs={false}
          showIndicators={true}
          autoPlay={false}
          renderIndicator={(clickEvent, selected) => (
            <div
              style={{
                position: "relative",
                top: "20px",
                background: selected ? "#1259ff" : "rgba(0,0,0,0.2)",
                borderColor: selected ? "#fff" : "#111",
                borderWidth: "2px",
                width: "10px",
                height: "10px",
                borderRadius: "5px",
                display: "inline-flex",
                margin: "5px",
              }}
              onClick={clickEvent}
            ></div>
          )}
        >
          <div className="row">
            <div className="col-lg-2 nflex">
              <h4>1</h4>
            </div>
            <div className="col-lg-4 col-12 hflex">
              <div className="stepscontent">
                <h1>Register with us</h1>
                <p className="stepsp1">
                  By clicking{" "}
                  <Link to="hero" spy={true} smooth={true}>
                    <span className="registerC">REGISTER</span>
                  </Link>{" "}
                  you will start the process to open up your very own secure
                  trading account. Complete the fields on the registration form
                  and once done, click on the verification link sent to you by
                  email. By verifying your email we will generate your own
                  secure and private trading portal and redirect you to it.
                </p>
              </div>
            </div>
            <div className="col-lg-6">
              {!isMobile && (
                <img src={step1} className="img-fluid mx-auto" alt=""></img>
              )}
            </div>
          </div>

          <div className="row">
            <div className="col-lg-2 nflex">
              <h4>2</h4>
            </div>
            <div className="col-lg-4 hflex">
              <div className="stepscontent">
                <h1>Verify your email</h1>
                <p className="stepsp1">
                  Click on the verification link sent to you by email. By
                  verifying your email we will generate your own secure and
                  private trading portal and redirect you to it.
                </p>
                <p className="stepsp2">
                  Ensure to whitelist any emails from TIOmarkets by clicking
                  ‘this email is safe’, when prompted, and to ensure you don’t
                  miss any notifications.
                </p>
              </div>
            </div>
            <div className="col-lg-6">
              {!isMobile && (
                <img src={step2} className="img-fluid mx-auto" alt=""></img>
              )}
            </div>
          </div>

          <div className="row">
            <div className="col-lg-2 nflex">
              <h4>3</h4>
            </div>
            <div className="col-lg-4 col-12 hflex">
              <div className="stepscontent">
                <h1>Fund your wallet</h1>
                <p className="stepsp1">
                  Select <span style={{ fontWeight: "bolder" }}>DEPOSIT</span>{" "}
                  from your{" "}
                  <a
                    href="https://clients.tiomarkets.com/"
                    className="registerC"
                    target="_blank"
                    rel="noreferrer"
                  >
                    client portal
                  </a>{" "}
                  and choose from a range of convenient funding methods such as
                  credit card, e-wallet or bank wire, in order to deposit your
                  initial investment into your secure portal. Any funds that you
                  deposit into your portal can be used to trade with on MT4 or
                  MT5, and you can withdraw at any time.
                </p>
                <p className="stepsp2">
                  Any funds withdrawn from your wallet will always be sent back
                  to the same source from which you funded.
                </p>
              </div>
            </div>
            <div className="col-lg-6">
              {!isMobile && (
                <img src={step4} className="img-fluid mx-auto" alt=""></img>
              )}
            </div>
          </div>

          <div className="row">
            <div className="col-lg-2 nflex">
              <h4>4</h4>
            </div>
            <div className="col-lg-4 col-12 hflex">
              <div className="stepscontent">
                <h1>Open Live Trading Account</h1>
                <p className="stepsp1">
                  Now that your account is funded, you are ready to access our
                  full range of CFD instruments, including forex, stocks,
                  indices, commodities, metals and more. Click to{" "}
                  <span style={{ fontWeight: "bolder" }}>
                    OPEN LIVE ACCOUNT
                  </span>{" "}
                  and select your preferred platform, account currency and
                  leverage in order to generate your trading account
                  credentials. When done, transfer your deposit from your wallet
                  into your live trading account, ready to trade.
                </p>
              </div>
            </div>
            <div className="col-lg-6">
              {!isMobile && (
                <img src={step5} className="img-fluid mx-auto" alt=""></img>
              )}
            </div>
          </div>

          <div className="row">
            <div className="col-lg-2 nflex">
              <h4>5</h4>
            </div>
            <div className="col-lg-4 col-12 hflex">
              <div className="stepscontent">
                <h1>Trade 2 lots</h1>
                <p className="stepsp1">
                  Visit the download centre from within your secure portal and
                  click to download MT4 or MT5 trading platform. Use the
                  credentials from your live trading account, which were sent to
                  your email, for logging in. Once ready, select the currency
                  pair from the watchlist that you wish to trade and click to
                  buy or sell your chosen quantity. Trading 2 lots will qualify
                  you to start earning as a TIOfriend!
                </p>
              </div>
            </div>
            <div className="col-lg-6">
              {!isMobile && (
                <img src={step5} className="img-fluid mx-auto" alt=""></img>
              )}
            </div>
          </div>
        </Carousel>
      </div>
    </div>
  );
}

export default Steps;
