import React from "react";
import "../styles/PaymentStructure.css";

function PaymentStructure() {
  const data = [
    {
      category: "Bronze",
      level1: ["15", "25", "35", "50", "65", "75", "100"],
      level2: ["7", "7", "7", "7", "7", "7", "7"],
      level3: ["4", "4", "4", "4", "4", "4", "4"],
      level4: ["3", "3", "3", "3", "3", "3", "3"],
      level5: ["1", "1", "1", "1", "1", "1", "1"],
      level6: ["0.5", "0.5", "0.5", "0.5", "0.5", "0.5", "0.5"],
      totals: ["30.5", "40.5", "50.5", "65.5", "80.5", "90.5", "115.5"],
      color: "orange",
    },
    {
      category: "Silver",
      level1: ["50", "60", "70", "80", "90", "100", "250"],
      level2: ["7", "7", "7", "7", "7", "7", "7"],
      level3: ["4", "4", "4", "4", "4", "4", "4"],
      level4: ["3", "3", "3", "3", "3", "3", "3"],
      level5: ["1", "1", "1", "1", "1", "1", "1"],
      level6: ["0.5", "0.5", "0.5", "0.5", "0.5", "0.5", "0.5"],
      totals: ["65.5", "75.5", "85.5", "95.5", "105.5", "115.5", "265.5"],
      color: "silver",
    },
    {
      category: "Gold",
      level1: ["100", "150", "200", "250", "300", "350", "500"],
      level2: ["7", "7", "7", "7", "7", "7", "7"],
      level3: ["4", "4", "4", "4", "4", "4", "4"],
      level4: ["3", "3", "3", "3", "3", "3", "3"],
      level5: ["1", "1", "1", "1", "1", "1", "1"],
      level6: ["0.5", "0.5", "0.5", "0.5", "0.5", "0.5", "0.5"],
      totals: ["115.5", "165.5", "215.5", "265.5", "315.5", "365.5", "515.5"],
      color: "gold",
    },
    {
      category: "Platinum",
      level1: ["200", "275", "350", "425", "500", "575", "750"],
      level2: ["7", "7", "7", "7", "7", "7", "7"],
      level3: ["4", "4", "4", "4", "4", "4", "4"],
      level4: ["3", "3", "3", "3", "3", "3", "3"],
      level5: ["1", "1", "1", "1", "1", "1", "1"],
      level6: ["0.5", "0.5", "0.5", "0.5", "0.5", "0.5", "0.5"],
      totals: ["215.5", "290.5", "365.5", "440.5", "515.5", "590.5", "765.5"],
      color: "grey",
    },
    {
      category: "Diamond",
      level1: ["400", "500", "600", "700", "800", "900", "1000"],
      level2: ["7", "7", "7", "7", "7", "7", "7"],
      level3: ["4", "4", "4", "4", "4", "4", "4"],
      level4: ["3", "3", "3", "3", "3", "3", "3"],
      level5: ["1", "1", "1", "1", "1", "1", "1"],
      level6: ["0.5", "0.5", "0.5", "0.5", "0.5", "0.5", "0.5"],
      totals: ["415.5", "515.5", "615.5", "715.5", "815.5", "915.5", "1015.5"],
      color: "white",
    },
  ];
  return (
    <div id="PaymentStructure">
      <h1 className="titleP">TIOFRIENDS</h1>
      <h1 className="titleP2">Payment Structure</h1>
      <div className="payTables">
        {data.map((item) => (
          <div className="tablePay" style={{ background: item.color }}>
            <h4>{item.category}</h4>
            <table>
              <tr>
                <td>Level 1</td>
                {item.level1.map((arr) => (
                  <td>{arr}</td>
                ))}
              </tr>
              <tr>
                <td>Level 2</td>
                {item.level2.map((arr) => (
                  <td>{arr}</td>
                ))}
              </tr>
              <tr>
                <td>Level 3</td>
                {item.level3.map((arr) => (
                  <td>{arr}</td>
                ))}
              </tr>
              <tr>
                <td>Level 4</td>
                {item.level4.map((arr) => (
                  <td>{arr}</td>
                ))}
              </tr>
              <tr>
                <td>Level 5</td>
                {item.level5.map((arr) => (
                  <td>{arr}</td>
                ))}
              </tr>
              <tr>
                <td>Level 6</td>
                {item.level6.map((arr) => (
                  <td>{arr}</td>
                ))}
              </tr>
              <tr>
                <td>Total</td>
                {item.totals.map((arr) => (
                  <td>{arr}</td>
                ))}
              </tr>
            </table>
          </div>
        ))}
      </div>
    </div>
  );
}

export default PaymentStructure;
