export const getCurrency = (country) => {
  const EUROCountries = [
    "at",
    "bg",
    "hr",
    "cy",
    "cz",
    "dk",
    "ee",
    "fi",
    "fr",
    "de",
    "hu",
    "ie",
    "it",
    "lv",
    "lt",
    "lu",
    "mt",
    "aw",
    "pl",
    "pt",
    "ro",
    "sk",
    "si",
    "es",
    "se",
    "fr",
  ];
  const USDCountries = [
    "al",
    "dz",
    "ad",
    "ao",
    "ag",
    "ar",
    "am",
    "az",
    "bs",
    "bh",
    "bd",
    "bb",
    "by",
    "bz",
    "bj",
    "bt",
    "bo",
    "ba",
    "bw",
    "br",
    "bn",
    "bf",
    "bi",
    "cv",
    "kh",
    "cm",
    "cf",
    "td",
    "cl",
    "cn",
    "co",
    "km",
    "cg",
    "cr",
    "ci",
    "cu",
    "dj",
    "dm",
    "do",
    "cg",
    "ec",
    "eg",
    "sv",
    "gq",
    "er",
    "sz",
    "et",
    "fj",
    "ga",
    "gm",
    "ge",
    "gh",
    "gr",
    "gd",
    "gt",
    "gn",
    "gw",
    "gy",
    "ht",
    "va",
    "hn",
    "is",
    "in",
    "id",
    "il",
    "jm",
    "jp",
    "jo",
    "kz",
    "ke",
    "ki",
    "kw",
    "kg",
    "la",
    "lb",
    "ls",
    "lr",
    "ly",
    "li",
    "mg",
    "mw",
    "my",
    "mv",
    "ml",
    "mh",
    "mr",
    "mu",
    "mx",
    "fm",
    "md",
    "mc",
    "mn",
    "me",
    "ma",
    "mz",
    "mm",
    "na",
    "nr",
    "np",
    "nz",
    "ni",
    "ne",
    "ng",
    "mk",
    "no",
    "om",
    "pk",
    "pw",
    "pa",
    "pg",
    "py",
    "pe",
    "ph",
    "qa",
    "ru",
    "rw",
    "kn",
    "lc",
    "ws",
    "sm",
    "st",
    "sa",
    "sn",
    "rs",
    "sc",
    "sl",
    "sg",
    "sb",
    "za",
    "kr",
    "lk",
    "vc",
    "ss",
    "sr",
    "ch",
    "tj",
    "tz",
    "th",
    "tl",
    "tg",
    "to",
    "tt",
    "tn",
    "tr",
    "tm",
    "tv",
    "ug",
    "ua",
    "ae",
    "uy",
    "uz",
    "vu",
    "ve",
    "vn",
    "ye",
    "zm",
    "zw",
  ];
  const GBPCountires = ["gb"];
  const CADCountries = ["ca"];
  const AUDCountries = ["au"];
  let curr = "USD";
  switch (true) {
    case EUROCountries.indexOf(country) > -1:
      curr = "EUR";
      break;
    case USDCountries.indexOf(country) > -1:
      curr = "USD";
      break;
    case GBPCountires.indexOf(country) > -1:
      curr = "GBP";
      break;
    case CADCountries.indexOf(country) > -1:
      curr = "CAD";
      break;
    case AUDCountries.indexOf(country) > -1:
      curr = "AUD";
      break;
    default:
      curr = "USD";
      break;
  }
  return curr;
};
