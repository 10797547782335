import React from "react";
import { TIOlogo } from "../config/images";
import { Link } from "react-scroll";
import "../styles/NavBar.css";

function NavBar() {
  return (
    <div id="navBar">
      <div className="container">
        <div className="row navBarSp">
          <div className="left-side">
            <a href="https://tiomarkets.com/" target="_blank" rel="noreferrer">
              <img src={TIOlogo} alt="logo" />
            </a>
          </div>
          <div className="right-side">
            <div className="ctaS">
              <a
                className="login-btn"
                href="https://clients.tiomarkets.com/en/login"
              >
                Login
              </a>
              <Link to="hero" spy={true} smooth={true}>
                <button className="register-btn">Register</button>
              </Link>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default NavBar;
