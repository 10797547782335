import React from "react";
import "../styles/TestimonialCard.css";

function TestimonialCard({ testiTitle, testiContent, testiStars, testiName }) {
  return (
    <div className="testimonialCard">
      <div className="cardTesti">
        <h5>{testiTitle}</h5>
        <p className="cardContent">{testiContent}</p>
        <img src={testiStars} alt=""></img>
        <p>{testiName}</p>
      </div>
    </div>
  );
}

export default TestimonialCard;
