import Lottie from "react-lottie";
import loader from "../loading.json";

function Loader({ isVisible = false }) {
  if (!isVisible) return null;
  return (
    <div className="loader">
      <Lottie
        options={{ animationData: loader, autoplay: true, loop: true }}
        width={200}
        height={200}
      />
    </div>
  );
}

export default Loader;
