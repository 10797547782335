import React from "react";
import { isMobile } from "react-device-detect";
import "../styles/TermsPages.css";

function TermsPages() {
  return (
    <div id="pages">
      <div className="pageA4">
        <h4 id="tc1" className="TermsTitle">
          TIOfriends Terms & Conditions
        </h4>
        <h6> 1. SUMMARY & MINIMUM REQUIREMENTS</h6>
        <ol type="a">
          <li>
            <p>
              TIOfriends is a referral incentive programme available to verified
              clients of
              <a href="https://www.tiomarkets.com"> www.tiomarkets.com</a> who
              deposit at least $250 USD or currency equivalent, and who place at
              least two round turn lots.
            </p>
          </li>
          <li>
            <p>
              Clients wishing to participate should also download the mobile
              TIOfriends application on IOs or Android, and register, when
              prompted.
            </p>
          </li>
          <li>
            <p>
              {" "}
              For each friend introduced to TIOmarkets and who fulfils the
              minimum requirement of 250 USD minimum deposit and 2 lots traded,
              their introducer will receive a referral fee based on scale shown
              in clauses 2 and 3
            </p>
          </li>
          <li>
            <p>
              Both the introducer and the friend must meet the minimum deposit
              and trading requirements in order for the introducer to be
              eligible to receive a referral fee
            </p>
          </li>
        </ol>

        <h6 id="tc2">2. REFERRAL FEES FOR FIRST LEVEL INTRODUCERS</h6>
        <ol type="a">
          <li>
            <p>
              The referral fee varies according to the number of friends
              introduced, per the tables shown below.
            </p>
          </li>
          <li>
            <p>
              As soon as one friend meets the minimum deposit and trading
              requirements, then the referral fee will show in the introducers’
              app and will be added to his/her balance.
            </p>
          </li>
          <li>
            <p>Case Study:</p>{" "}
            <p>
              Trader A invites 9 friends. His/her total payout will consist of:
            </p>{" "}
            <p>15 + 25 + 35 + 50 + 65 + 75 + 100 + 50 + 60 = 475 USD</p>
          </li>
        </ol>

        <h5 id="tc3">Bronze level:</h5>
        {!isMobile && (
          <table>
            <tr className="bronzeRow">
              <td>Friend 1</td>
              <td>Friend 2</td>
              <td>Friend 3</td>
              <td>Friend 4</td>
              <td>Friend 5</td>
              <td>Friend 6</td>
              <td>Friend 7</td>
            </tr>

            <tr>
              <td>15 USD</td>
              <td>25 USD</td>
              <td>35 USD</td>
              <td>50 USD</td>
              <td>65 USD</td>
              <td>75 USD</td>
              <td>100 USD</td>
            </tr>
          </table>
        )}
        {isMobile && (
          <table className="mobTable">
            <td className="bronzeRow">
              <tr>Friend 1</tr>
              <tr>Friend 2</tr>
              <tr>Friend 3</tr>
              <tr>Friend 4</tr>
              <tr>Friend 5</tr>
              <tr>Friend 6</tr>
              <tr>Friend 7</tr>
            </td>

            <td>
              <tr>15 USD</tr>
              <tr>25 USD</tr>
              <tr>35 USD</tr>
              <tr>50 USD</tr>
              <tr>65 USD</tr>
              <tr>75 USD</tr>
              <tr>100 USD</tr>
            </td>
          </table>
        )}
        <h5 id="tc4">Silver level:</h5>
        {isMobile && (
          <table className="mobTable">
            <td className="silverRow">
              <tr>Friend 8</tr>
              <tr>Friend 9</tr>
              <tr>Friend 10</tr>
              <tr>Friend 11</tr>
              <tr>Friend 12</tr>
              <tr>Friend 13</tr>
              <tr>Friend 14</tr>
            </td>

            <td>
              <tr>50 USD</tr>
              <tr>60 USD</tr>
              <tr>70 USD</tr>
              <tr>80 USD</tr>
              <tr>90 USD</tr>
              <tr>100 USD</tr>
              <tr>250 USD</tr>
            </td>
          </table>
        )}

        {!isMobile && (
          <table>
            <tr className="silverRow">
              <td>Friend 8</td>
              <td>Friend 9</td>
              <td>Friend 10</td>
              <td>Friend 11</td>
              <td>Friend 12</td>
              <td>Friend 13</td>
              <td>Friend 14</td>
            </tr>

            <tr>
              <td>50 USD</td>
              <td>60 USD</td>
              <td>70 USD</td>
              <td>80 USD</td>
              <td>90 USD</td>
              <td>100 USD</td>
              <td>250 USD</td>
            </tr>
          </table>
        )}
      </div>

      <div className="pageA4">
        <h5 id="tc5">Gold level:</h5>
        {!isMobile && (
          <table>
            <tr className="goldRow">
              <td>Friend 15</td>
              <td>Friend 16</td>
              <td>Friend 17</td>
              <td>Friend 18</td>
              <td>Friend 19</td>
              <td>Friend 20</td>
              <td>Friend 21</td>
            </tr>

            <tr>
              <td>100 USD</td>
              <td>150 USD</td>
              <td>200 USD</td>
              <td>250 USD</td>
              <td>300 USD</td>
              <td>350 USD</td>
              <td>500 USD</td>
            </tr>
          </table>
        )}
        {isMobile && (
          <table className="mobTable">
            <td className="goldRow">
              <tr>Friend 15</tr>
              <tr>Friend 16</tr>
              <tr>Friend 17</tr>
              <tr>Friend 18</tr>
              <tr>Friend 19</tr>
              <tr>Friend 20</tr>
              <tr>Friend 21</tr>
            </td>

            <td>
              <tr>100 USD</tr>
              <tr>150 USD</tr>
              <tr>200 USD</tr>
              <tr>250 USD</tr>
              <tr>300 USD</tr>
              <tr>350 USD</tr>
              <tr>500 USD</tr>
            </td>
          </table>
        )}

        <h5 id="tc6">Platinum level:</h5>

        <table>
          <tr className="platRow">
            <td>Friend 21 Onwards</td>
          </tr>

          <tr>
            <td>250 USD</td>
          </tr>
        </table>

        <h6 id="tc7">3. REFERRAL FEES FOR ALL LEVELS</h6>
        <ol type="a">
          <li>
            <p>
              When an introducer completes the Bronze level, thereby having
              introduced 7 friends who meet the minimum deposit and trade
              requirements, then the introducer will be eligible to receive
              multi-level payments.
            </p>
          </li>
          <li>
            <p>The multi-level functionality works as follows:</p>
            <ol type="i">
              <li>
                The introducer can receive an introducer’s fee for clients that
                have been introduced by his qualified referrals
              </li>
              <li>
                The introducer may earn a referral fee for clients referred from
                his referrals and from their referrals and so on, up to a
                maximum of 6 levels of payment, per the below table.
              </li>
              <li>
                <p style={{ textDecoration: "underline" }}>Case study 1:</p>
                <p style={{ fontStyle: "italic" }}>
                  Client A introduces Client B who introduces two friends. In
                  this case, Client A receives 15 USD for the original
                  introduction plus 7 USD for each of the traders introduced by
                  his friend.So: 15+7+7 USD. In this case, Client B also
                  receives 15+25 USD.
                </p>
              </li>
              <li>
                <p style={{ textDecoration: "underline" }}>Case study 2:</p>
                <p style={{ fontStyle: "italic" }}>
                  Client 1 introduces Client 2, who introduces Client 3, who
                  introduces client 4. In this case, Client 1 receives three
                  levels of rewards. So: 15+7+4 USD. Client 2 receives two
                  levels of rewards, so: 15+7 USD. And Client 3 receives the
                  first level of rewards, so: 15 USD.
                </p>
              </li>
            </ol>
          </li>
        </ol>
      </div>

      <div className="pageA4">
        <h6 id="tc8">4. APP FUNCTIONALITY</h6>
        <ol type="a">
          <li>
            <p>The introducer will be able to use the TIOfriends app to:</p>
            <ol type="i">
              <li>Invite friends to trade with TIOmarkets</li>
              <li>
                Monitor which of their friends has registered and met the
                minimum requirements
              </li>
              <li>Remind their friends to meet the minimum requirements</li>
              <li>Monitor their balance</li>
              <li>Monitor their progress within the reward chart</li>
              <li>Spin the wheel at the end of each level</li>
            </ol>
          </li>

          <li>
            <p>The introducer will not be able to use the TIOfriends app to:</p>
            <ol type="i">
              <li>
                Deposit funds: Any fund management should occur within the
                tiomarkets portal
              </li>
              <li>
                Withdraw funds. Any fund management should occur within the
                tiomarkets portal
              </li>
              <li>
                Trade. All trading occurs on MT4 and MT4 platforms via live
                trading accounts opened within the TIOmarkets portal
              </li>
            </ol>
          </li>
        </ol>

        <h6 id="tc9">5. ELIGIBILITY</h6>
        <ol type="a">
          <li>
            Only clients of the TIO Markets Ltd (St Vincent’s entity) are
            eligible to benefit from this Promotion. Introducers or referrals
            wishing to benefit from the TIOfriends programme who register with
            TIO Markets UK Ltd do not qualify.
          </li>
          <li>
            To become a qualified referral under the TIOfriends programme, and
            for the introducer to receive a referral fee, the referral must
            become a verified client of TIO Markets Ltd (St Vincent’s entity),
            deposit a minimum of $250 or currency equivalent and trade at least
            two round turn lots on any instrument.
          </li>
          <li>
            The trading requirement can occur on any of the following account
            types: Standard, Signature, VIP, VIP Black. PAMM and Demo accounts
            are exempt from this promotion.
          </li>
          <li>
            Both introducers and their referrals must meet the minimum deposit
            and trading requirements to be eligible for this Promotion. There is
            no maximum deposit size or maximum trading volume / size requirement
          </li>
          <li>
            Deposits across all accepted currencies will be eligible for this
            promotion including, TIOx
          </li>
          <li>
            Introducing Brokers (IBs) of TIOmarkets and their referrals are not
            eligible to participate in this Promotion, even should they meet the
            minimum requirements, unless at the Company discretion specific
            exceptions are allowed.
          </li>
          <li>
            This Promotion is not available in conjunction with any other
            TIOmarkets Promotion. In cases of any promotional conflict whereby a
            referral has applied for a company promotion, it is at the Company’s
            discretion to decide whether the Introducer will receive a referral
            fee OR if the client will receive the requested promotion. Both
            cannot occur.
          </li>
        </ol>
      </div>

      <div className="pageA4">
        <h6 id="tc10">6. GENERAL DECORUM IN PROMOTION OF FINANCIAL PRODUCTS</h6>
        <ol type="a">
          <li>
            <p>
              This promotion is for referring friends to trade with TIOmarkets.
              We expect that the introducer should behave with appropriate
              decorum while promoting TIOmarkets’ services. In specific:
            </p>
            <ol type="i">
              <li>
                The introducer should have a personal connection to the person
                that they are inviting to TIOmarkets
              </li>
              <li>
                The introducer should not spam their network or send too many
                reminders in a way that may constitute spamming or harassment
              </li>
              <li>
                The introducer should not over-solicit to his/her network or
                over-promote our services in any way that could be considered
                aggressive promotion or harassment
              </li>
              <li>
                The introducer should not blindly share his/her referral link on
                social networks, forums or otherwise in order to avoid enticing
                / soliciting clients whose geo-location, investment status,
                financial knowledge, experience or risk appetite may not qualify
                them to trade with TIOmarkets
              </li>
              <li>
                {" "}
                The introducer should be aware, and make their friends aware of
                the risks of trading. Specifically:
                <ol type="1" className="CFDssize">
                  <li>
                    CFDs are complex instruments and come with a high risk of
                    losing money rapidly due to leverage. You should consider
                    whether you understand how CFDs work and whether you can
                    afford to take the high risk of losing your money. Never
                    deposit more than you are prepared to lose. Professional
                    client's losses can exceed their deposit. Please see our{" "}
                    <a href="https://tiomarkets.com/legal-documents/TM%20Risk%20Disclosure%20Complex%20Financial%20Instruments.pdf">
                      risk warning policy
                    </a>{" "}
                    and seek independent professional advice if you do not fully
                    understand.
                  </li>
                </ol>
              </li>
            </ol>
          </li>
        </ol>

        <h6 id="tc11">7. PAYMENTS & BALANCES</h6>
        <ol type="a">
          <li>
            <p>
              The introducer will receive a referral fee only for friends who
            </p>
            <ol type="i">
              <li>
                {" "}
                register with TIOmarkets via their unique referral link, which
                can be found within the app.
              </li>
              <li>Complete the minimum deposit requirement</li>
              <li>Complete the minimum trading requirement</li>
            </ol>
          </li>

          <li>
            Once the above are completed, this referral fee will show as an
            available balance within the app, and the funds will be transferred
            to the client wallet once per month, ready to withdraw.
          </li>
          <li>
            The fee for friends who do not yet meet the minimum deposit and
            trading requirements will not show in the introducer’s app as
            available balance
          </li>
          <li>
            Pending balance will be shown in the introducer’s app for referrals
            of theirs who have referred qualified traders (see clause 3. for the
            multi-level functionality). The introducer will not be eligible to
            access those funds until he/she has completed the bronze level (7
            qualified introductions). Once the introducer completes 7
            first-level introductions, then the pending balance will convert
            into available balance.
          </li>
          <li>
            Any client who signs up directly with{" "}
            <a href="https://tiomarkets.com/">www.tiomarkets.com</a> or{" "}
            <a href="https://tiofriends.com/">www.tiofriends.com </a>
            will not be credited to a specific introducer.
          </li>
        </ol>
      </div>
      <div className="pageA4">
        <ol type="a" start="6">
          <li>
            {" "}
            Referrals who register with TIOmarkets and who are not assigned to a
            specific introducer, can not be assigned to a specific introducer
            should they (or an introducer) request it.
          </li>
          <li>
            Introducers will receive their TIOfriends referral fees / balance
            into their TIOmarkets wallet which can be found on
            clients.tiomarkets.com within the first 6 days of every month. The
            company will not make payments outside of this payment window
          </li>
          <li>
            Payments made to TIOfriends introducers are
            <ol type="i">
              <li>fully withdrawable or</li>
              <li>
                they can be reinvested into a live trading account in order to
                trade one or more of our instruments on MT4/MT5 or
              </li>
              <li>
                can be used to purchase the company’s native token, the TIOx,
                which is on the Ethereum blockchain.
              </li>
            </ol>
          </li>
          <li>
            Each trading client or referral may only be registered under one
            introducer. If any referral signs up more than once using a
            different TIOfriends introducer code, the subsequent introducers
            will not receive a referral fee for this client.
          </li>
          <li>
            Each introducer will only get one referral fee for each client.
            Introducers will not get additional payouts for referrals of theirs
            who exceed the minimum deposit or who place more than 2 lots trading
            or who open more than one trading account with TIOmarkets.
          </li>
        </ol>

        <h6 id="tc12">8. ABUSIVE BEHAVIOURS</h6>

        <ol type="a">
          <li>
            <p>
              Any referrals who are deemed to be abusing the terms TIOmarkets
              terms and conditions of trading will be nullified from the
              TIOfriends promotion and the introducer of said abuser will not
              receive a referral fee.
            </p>
            <ol type="i">
              <li>
                If the referral is deemed to be abusing the terms and conditions
                of trading after the introducer has already received the
                referral fee for this client, then the Company maintains the
                right to deduct the referral fee from said client from the
                introducer’s balance or any pending payouts.
              </li>
              <li>
                If the introducer is deemed to be abusing the company trading
                terms or its promotions in any way, this person forfeits their
                entire current, potential and future payout. Payments will be
                halted and withdrawals may be prohibited at the Company
                discretion.
              </li>
              <li>
                Any indication or suspicion of fraud, manipulation, cash-back
                arbitrage, or other forms of deceitful or fraudulent activity in
                a client’s account with TIOmarkets or otherwise related or
                connected to the Promotion will nullify any and all transactions
                carried and/or profits or losses garnered therein.
              </li>
              <li>
                TIOmarkets reserves the right, at its sole discretion, to
                disqualify any individual that misuses or attempts to misuse the
                Promotion, or breaches the present Terms and Conditions and/or
                any of TIOmarkets’ Business Terms and to cancel all orders and
                annul all profits of such client. In these circumstances,
                TIOmarkets shall not be liable for any consequences of the
                Promotion cancelation.
              </li>
            </ol>
          </li>
        </ol>
      </div>
      <div className="pageA4">
        <h6 id="tc13">9. GENERAL TERMS & DISCLOSURE</h6>

        <ol type="a">
          <li>
            <p>
              TIOfriends is an offering of TIO Markets Ltd, TIO Markets Ltd. a
              Company registered in Saint Vincent and the Grenadines as an
              International Business Company with registration number 24986 IBC
              2018. The registered Office of the Company is Suite 305, Griffith
              Corporate Center, Beachmont, P.O. Box 1510, Kingstown, Saint
              Vincent and the Grenadines. TIOmarkets is a trading name of TIO
              Markets Ltd.
            </p>
          </li>
          <li>
            <p>
              Any changes to this Promotion shall be at the discretion of
              TIOmarkets without any prior notice.
            </p>
          </li>
          <ol type="i">
            <li>a breach of these Terms and Conditions occurs;</li>
            <li>
              Upon expiration of the Promotion, the trading terms are not
              satisfied;
            </li>
            <li>
              The Company has reasonable grounds to believe a misuse of the
              Promotion;
            </li>
            <li>
              Client attempts to withdraw any part of the Promotion before
              satisfying the Trading Terms.
            </li>
            <li>
              TIOmarkets has reasonable suspicion that the Client has already or
              intends to takes advantage of the Promotion.
            </li>
          </ol>

          <li>
            TIOmarkets will not be liable for any margin call or losses that you
            may suffer, including but not limited to losses due to Stop-out.
          </li>
          <li>
            TIOmarkets reserves the right, as it in its sole discretion deems
            fit, to alter, amend, suspend, cancel or terminate the Promotion, or
            any aspect of the Promotion, at any time and without any prior
            notice.
          </li>
          <li>
            Under no circumstances shall TIOmarkets be liable for any
            consequences of any alteration, amendment, suspension, cancelation
            or termination of the Promotion.
          </li>
          <li>
            In the event of any dispute or misinterpretation of the above
            applicable Terms and Conditions, such dispute or misinterpretation
            shall be resolved as TIOmarkets acting in good faith and as it
            shall, in its sole and absolute discretion, deem fit and proper.
            TIOmarkets decision shall be final and binding.
          </li>
          <li>
            By participating in the Promotion, the Client acknowledges that they
            have read and agreed to be bound by these terms and conditions of
            the Promotion together with TIOmarkets’ terms and conditions of
            trading.
          </li>
          <li>
            Notwithstanding the translated language of this document, the
            English wording shall be the binding version in the event of any
            discrepancy between the two languages.
          </li>
        </ol>
      </div>
    </div>
  );
}

export default TermsPages;
