export const visaLogo = "../images/visa.svg";
export const bankwire = "../images/bank-wire.svg";
export const masterLogo = "../images/mastercard.png";
export const neteller = "../images/neteller.png";
export const sepa = "../images/single-euro.png";
export const skrill = "../images/skrill.svg";
export const android = "../images/android.svg";
export const ios = "../images/ios.svg";
export const referphone = "../images/referphone.png";
export const referplus = "../images/referplus.svg";
export const refercircle = "../images/refercircle.svg";
export const refCirclePlus = "../images/referplus.png";
export const fiveStars = "../images/stars.svg";
export const logoTIO = "../images/tiomarkets-logo-orange.svg";
export const facebookTIO = "../images/social/facebook-f-brands.svg";
export const instagramTIO = "../images/social/instagram-brands.svg";
export const linkedinTIO = "../images/social/linkedin-in-brands.svg";
export const twitterTIO = "../images/social/twitter-brands.svg";
export const youtubeTIO = "../images/social/youtube-brands.svg";
export const step1 = "../images/step1.gif";
export const step2 = "../images/step2.gif";
export const step3 = "../images/step3.gif";
export const step4 = "../images/step4.gif";
export const step5 = "../images/step5.gif";
export const arrowDown = "../images/mdown.svg";
export const book = "../images/book.svg";
export const fast = "../images/fast.svg";
export const liveChat = "../images/chat.svg";
export const IndicesImg = "../images/forex.svg";
export const ForexImg = "../images/FOREX.png";
export const EnergiesImg = "../images/energies.png";
export const StocksImg = "../images/stocks.png";
export const MetalsImg = "../images/gold.png";
export const MobilePrices = "../images/prices.gif";
export const Apple = "../images/apple.png";
export const Dollar = "../images/dollar.png";
export const thankyou = "../images/thankyou-icon.svg";
export const emailChange = "../images/emailChange.svg";
export const TIOlogo = "../images/tiomarkets-logo-orange.png";
export const TIOthumbnail = "../images/thumbnailall.jpg";
export const iosSoon = "../images/comingSoonIOS.svg";
