import React, { createContext, useState } from "react";
export const AppContext = createContext();

export const UserDataProvider = ({ children }) => {
  const [modalHide, setModalHide] = useState(false);
  const [email, setEmail] = useState();
  const [loading, setLoading] = useState(false);

  const userData = {
    modalHide,
    setModalHide,
    email,
    setEmail,
    loading,
    setLoading,
  };

  return <AppContext.Provider value={userData}>{children}</AppContext.Provider>;
};
