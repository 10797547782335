import React, { useState, useEffect } from "react";
import "../styles/ReferFriend.css";
import {
  referphone,
  refCirclePlus,
  iosSoon,
  android,
} from "../config/images.js";

function ReferFriend() {
  const [isAnimate, setIsAnimate] = useState(true);

  useEffect(() => {
    setTimeout(() => {
      setIsAnimate(!isAnimate);
    }, 1200);
  }, [isAnimate]);
  return (
    <div id="referafriend">
      <div className="container">
        <div className="row">
          <div className="col-lg-6 hflex">
            <div className="referPhone">
              <img
                src={referphone}
                alt="Refer a friend"
                className="img-fluid mx-auto"
              />
              <img
                src={refCirclePlus}
                alt="Refer a friend"
                className="refercircle"
              />
            </div>
          </div>

          <div className="col-lg-6 hflexRight">
            {/* <h4 className="recCsoon">Coming Soon</h4> */}
            <h3>Invite your friends & get paid</h3>
            <div className="content">
              <p>
                Got friends who are interested in trading the financial markets?
                For each friend that you introduce to us, we'll pay you. More
                friends? More money!
              </p>
            </div>
            <div className="download">
              <img
                src={iosSoon}
                alt="Refer a friend Ios"
                className="referios"
              />

              <a
                href="https://play.google.com/store/apps/details?id=com.tiofriends.app"
                target="_blank"
                rel="noreferrer"
              >
                <img
                  src={android}
                  alt="Refer a friend Android"
                  className="referandroid"
                />
              </a>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default ReferFriend;
