import React from "react";
import "../styles/Deposits.css";

import {
  visaLogo,
  bankwire,
  masterLogo,
  neteller,
  sepa,
  skrill,
} from "../config/images";

function Deposits() {
  const depositlogo = [visaLogo, bankwire, masterLogo, neteller, sepa, skrill];
  return (
    <div id="deposits">
      <h1>We accept deposits in</h1>
      <div className="hflex">
        {depositlogo.map((item, idx) => (
          <img src={item} key={idx} className="mx-auto img-fluid" alt="" />
        ))}
      </div>
    </div>
  );
}

export default Deposits;
