import React from "react";
import "../styles/TradingInfo.css";
import { Carousel } from "react-responsive-carousel";
import { BrowserView, isMobile } from "react-device-detect";
import TradingBox from "./TradingBox";
import {
  IndicesImg,
  ForexImg,
  EnergiesImg,
  StocksImg,
  MetalsImg,
} from "../config/images";

function TradingInfo() {
  const tradedata = [
    {
      boxTitle: "Forex",
      boxContent:
        "Trade 70+ FX pairs on the world’s fastest moving and most liquid market. Benefit from tight spreads and fast order execution.",
      bg: "transparent linear-gradient(180deg, #FF7A04 0%, #C14502 100%) 0% 0% no-repeat padding-box",
      img: ForexImg,
    },
    {
      boxTitle: "Stocks",
      boxContent:
        "Go long or short on the world’s most traded international companies such as Google, Netflix, Amazon, Apple, Tesla, Facebook and many more.",
      bg: " transparent linear-gradient(180deg, #042BFF 0%, #021680 100%) 0% 0% no-repeat padding-box",
      img: StocksImg,
    },
    {
      boxTitle: "Energies",
      boxContent: "Trade oil and gas at a low cost on futures or spot markets.",
      bg: "transparent linear-gradient(180deg, #9E04FF 0%, #4A00A0 100%) 0% 0% no-repeat padding-box",
      img: EnergiesImg,
    },
    {
      boxTitle: "Indices",
      boxContent:
        "Trade major indices from the US, UK, Asia, Australia and Europe and benefit from tight spreads and fast order execution.",
      bg: "transparent linear-gradient(180deg, #ACACAC 0%, #565656 100%) 0% 0% no-repeat padding-box",
      img: IndicesImg,
    },
    {
      boxTitle: "Metals",
      boxContent:
        "Trade Gold, Silver and Platinum with low-cost trading conditions",
      bg: "transparent linear-gradient(180deg, #95B900 0%, #005D32 100%) 0% 0% no-repeat padding-box",
      img: MetalsImg,
    },
  ];

  return (
    <div id="tradinginfo">
      <BrowserView>
        <div className="container-fluid">
          <div className="row carouselrow">
            <div className="col-lg-12 carouselcustom">
              {tradedata.map((item) => (
                <TradingBox
                  key={item.boxTitle}
                  boxImg={item.img}
                  boxTitle={item.boxTitle}
                  boxContent={item.boxContent}
                  bg={item.bg}
                />
              ))}
              {tradedata.map((item) => (
                <TradingBox
                  key={item.boxTitle}
                  boxImg={item.img}
                  boxTitle={item.boxTitle}
                  boxContent={item.boxContent}
                  bg={item.bg}
                />
              ))}
              {tradedata.map((item) => (
                <TradingBox
                  key={item.boxTitle}
                  boxImg={item.img}
                  boxTitle={item.boxTitle}
                  boxContent={item.boxContent}
                  bg={item.bg}
                />
              ))}
            </div>
          </div>
        </div>
      </BrowserView>

      {isMobile && (
        <Carousel
          axis="horizontal"
          infiniteLoop
          centerMode={false}
          showArrows={!isMobile}
          showStatus={false}
          showThumbs={false}
          showIndicators={true}
          renderIndicator={(clickEvent, selected) => (
            <div
              style={{
                position: "relative",
                top: "10px",
                background: selected ? "#1259ff" : "rgba(0,0,0,0.2)",
                borderColor: selected ? "#fff" : "#111",
                borderWidth: "2px",
                width: "10px",
                height: "10px",
                borderRadius: "5px",
                display: "inline-flex",
                margin: "5px",
              }}
              onClick={clickEvent}
            ></div>
          )}
        >
          {tradedata.map((item) => (
            <TradingBox
              key={item.boxTitle}
              boxImg={item.img}
              boxTitle={item.boxTitle}
              boxContent={item.boxContent}
              bg={item.bg}
            />
          ))}
        </Carousel>
      )}
    </div>
  );
}

export default TradingInfo;
