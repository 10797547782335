import React, { useContext } from "react";
import { BrowserRouter as Router, Switch, Route } from "react-router-dom";
import "./App.css";
import "./styles/bootstrap-coq.css";
import Perks from "./components/Perks";
import Hero from "./components/Hero";
import Steps from "./components/Steps";
import TradingInfo from "./components/TradingInfo";
import Deposits from "./components/Deposits";
import ReferFriend from "./components/ReferFriend";
import Testimonials from "./components/Testimonials";
import Footer from "./components/Footer";
import FormSubmitted from "./components/FormSubmitted";
import { UserDataProvider, AppContext } from "./util/Manager";
import NavBar from "./components/NavBar";

const Structure = () => {
  const appContext = useContext(AppContext);
  return (
    <div className="App">
      <NavBar />
      <Router>
        <Switch>
          <Route path="/:ids" render={(props) => <Hero props={props} />} />
          <Route path="/" render={(props) => <Hero props={props} />} />
        </Switch>
      </Router>
      <Perks />
      <Steps />
      <TradingInfo />
      <Deposits />
      <ReferFriend />
      <Testimonials />
      <Footer />
      <FormSubmitted visible={appContext.modalHide} />
    </div>
  );
};

export default function App() {
  return (
    <UserDataProvider>
      <Structure />
    </UserDataProvider>
  );
}
