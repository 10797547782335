import React from "react";
import ReactDOM from "react-dom";
import "./index.css";
import App from "./App";

import { BrowserRouter as Router, Switch, Route } from "react-router-dom";
import reportWebVitals from "./reportWebVitals";
import MyPdf from "./components/Terms";
import PaymentStructure from "./components/PaymentStructure";
import PrivacyPolicy from "./components/PrivacyPolicy";

ReactDOM.render(
  <React.StrictMode>
    <Router>
      <Switch>
        <Route exact path="/payment_structure">
          <PaymentStructure />
        </Route>
        <Route exact path="/terms_conditions">
          <MyPdf />
        </Route>
        <Route exact path="/privacy_policy">
          <PrivacyPolicy />
        </Route>
        <Route path="">
          <App />
        </Route>
      </Switch>
    </Router>
  </React.StrictMode>,
  document.getElementById("root")
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
