import React, { useContext } from "react";
import "../styles/FormSubmitted.css";
import { thankyou, emailChange } from "../config/images";

import { AppContext } from "../util/Manager";

function FormSubmitted({ visible = false }) {
  const appContext = useContext(AppContext);

  if (!visible) return null;
  return (
    <div id="thankYouMessage">
      <div className="boxSubmitted">
        <div id="thank-you-box">
          <div className="thank-you-container">
            <div className="iconBox">
              <img src={thankyou} alt="thank-you" />
            </div>
            <div className="textRow">
              <p className="larger">
                We've sent an email to{" "}
                <span className="emailSpan">{appContext.email}</span>
              </p>
              <p className="smaller">
                Please <strong>verify</strong> the link in your email so we can
                create an account for you.
              </p>
            </div>
            <div className="userChecks">
              <div className="userCheck">
                <div className="iconCheck">
                  <img src={emailChange} alt="checkSpam" />
                </div>
                <div className="textCheck">
                  <p>
                    Check your spam and junk folders & whitelist emails from
                    tiomarkets.com
                  </p>
                </div>
              </div>
            </div>
            <div className="button-container">
              <button
                className="close-thank-you"
                onClick={() => {
                  appContext.setModalHide(false);
                }}
              >
                Close
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default FormSubmitted;
