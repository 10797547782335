import React from "react";
import "../styles/TradingBox.css";

function TradingBox({
  bg = "transparent linear-gradient(180deg, #FF7A04 0%, #C14502 100%) 0% 0% no-repeat padding-box",
  boxImg,
  boxTitle,
  boxContent,
}) {
  return (
    <div className="tradingbox" style={{ background: bg }}>
      <img src={boxImg} alt=""></img>
      <h3>{boxTitle}</h3>
      <p>{boxContent}</p>
    </div>
  );
}

export default TradingBox;
