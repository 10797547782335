import React from "react";
import "../styles/Perks.css";
import { arrowDown, book, fast, liveChat } from "../config/images";
function Perks() {
  return (
    <div id="perks">
      <div className="container">
        <div className="row">
          <div className="perk col-lg-3 col-6">
            <img
              alt="Forex Trading"
              className="perkimg img-fluid mx-auto"
              src={arrowDown}
            ></img>
            <p>$0 commissions per lot</p>
          </div>
          <div className="perk col-lg-3 col-6">
            <img
              alt="Forex Trading"
              className="perkimg img-fluid mx-auto"
              src={book}
            ></img>
            <p>Raw spreads</p>
          </div>
          <div className="perk col-lg-3 col-6">
            <img
              alt="Forex Trading"
              className="perkimg img-fluid mx-auto"
              src={fast}
            ></img>
            <p>Instant execution</p>
          </div>
          <div className="perk col-lg-3 col-6">
            <img
              alt="Forex Trading"
              className="perkimg img-fluid mx-auto"
              src={liveChat}
            ></img>
            <p>Live chat support</p>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Perks;
