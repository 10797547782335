import React from "react";
import "../styles/Footer.css";
import {
  logoTIO,
  facebookTIO,
  instagramTIO,
  linkedinTIO,
  twitterTIO,
  youtubeTIO,
} from "../config/images";

function Footer() {
  return (
    <div id="footer">
      <div className="container">
        <div className="row">
          <div className="col-lg-12 footer-borders first-row">
            <div className="row">
              <div className="col-sm-6 footer-logo">
                <a href="https://tiomarkets.com/">
                  <img alt="TIOmarkets" src={logoTIO} />
                </a>
              </div>
              <div className="col-sm-6">
                <ul className="link-list social-iconz">
                  <li>
                    <a
                      className="sitemap-link socialLink"
                      href="https://www.facebook.com/TIOMarkets"
                      target="_blank"
                      rel="noreferrer"
                    >
                      <img
                        className="socialIcons"
                        alt="TIOmarkets Facebook"
                        src={facebookTIO}
                      />
                    </a>
                  </li>
                  <li>
                    <a
                      className="sitemap-link socialLink"
                      href="https://twitter.com/TIOMarkets"
                      target="_blank"
                      rel="noreferrer"
                    >
                      <img
                        className="socialIcons"
                        alt="TIOmarkets Twitter"
                        src={twitterTIO}
                      />
                    </a>
                  </li>
                  <li>
                    <a
                      className="sitemap-link socialLink"
                      href="https://www.instagram.com/tiomarkets"
                      target="_blank"
                      rel="noreferrer"
                    >
                      <img
                        className="socialIcons"
                        alt="TIOmarkets Instagram"
                        src={instagramTIO}
                      />
                    </a>
                  </li>
                  <li>
                    <a
                      className="sitemap-link socialLink"
                      href="https://www.linkedin.com/company/tiomarkets"
                      target="_blank"
                      rel="noreferrer"
                    >
                      <img
                        className="socialIcons"
                        alt="TIOmarkets LinkedIn"
                        src={linkedinTIO}
                      />
                    </a>
                  </li>
                  <li>
                    <a
                      className="sitemap-link socialLink"
                      href="https://www.youtube.com/c/TIOmarkets"
                      target="_blank"
                      rel="noreferrer"
                    >
                      <img
                        className="socialIcons"
                        alt="TIOmarkets YouTube Channel"
                        src={youtubeTIO}
                      />
                    </a>
                  </li>
                </ul>
              </div>
            </div>
          </div>

          <div className="col-lg-12 footer-borders second-row">
            <div className="row">
              <div className="col-lg-6">
                <p className="sitemap-titles">Menu —</p>
                <ul className="link-list">
                  <li>
                    <a href="https://tiomarkets.com/instruments-overview">
                      <p>Markets</p>
                    </a>
                  </li>

                  <li>
                    <a href="https://tiomarkets.com/platforms">
                      <p>Platforms</p>
                    </a>
                  </li>

                  <li>
                    <a href="https://tiomarkets.com/conditions">
                      <p>Conditions</p>
                    </a>
                  </li>

                  <li>
                    <a href="https://tiomarkets.com/accounts">
                      <p>Accounts</p>
                    </a>
                  </li>
                  <li>
                    <a href="https://tiomarkets.com/education">
                      <p>Education</p>
                    </a>
                  </li>
                  <li>
                    <a href="https://tiomarkets.com/partners">
                      <p>Partners</p>
                    </a>
                  </li>

                  <li>
                    <a href="https://tiomarkets.com/contact">
                      <p>Contact</p>
                    </a>
                  </li>

                  <li>
                    <a href="https://tiomarkets.com/support">
                      <p>FAQ</p>
                    </a>
                  </li>
                  <li>
                    <a href="https://tiomarkets.com/careers">
                      <p>Careers</p>
                    </a>
                  </li>
                </ul>
              </div>
            </div>
          </div>

          <div className="col-lg-12 third-row disclaimer-regulation ">
            <div className="container">
              <div className="row">
                <div className="col-lg-12">
                  <p className="disclaimer">
                    <strong>Risk disclaimer:</strong> CFDs are complex
                    instruments and come with a high risk of losing money
                    rapidly due to leverage. You should consider whether you
                    understand how CFDs work and whether you can afford to take
                    the high risk of losing your money. Never deposit more than
                    you are prepared to lose. Professional client's losses can
                    exceed their deposit. Please see our risk warning policy and
                    seek independent professional advice if you do not fully
                    understand. This information is not directed or intended for
                    distribution to or use by residents of certain
                    countries/jurisdictions including, but not limited to, USA &
                    OFAC. The Company holds the right to alter the
                    aforementioned list of countries at its own discretion.
                  </p>
                </div>
              </div>
            </div>
          </div>
          <div className="col-lg-12 fourth-row">
            <p className="copyright">
              ©{new Date().getFullYear()} TIO Markets Ltd. All Rights Reserved.
            </p>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Footer;
