import React from "react";
import TermsPages from "./TermsPages";
import IndexingTerms from "./IndexingTerms";
import { isMobile } from "react-device-detect";
import "../styles/Terms.css";

function Terms() {
  return (
    <div id="TandC">
      {!isMobile && <IndexingTerms />}
      <TermsPages />
    </div>
  );
}

export default Terms;
