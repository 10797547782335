import React, { useContext } from "react";
import TradeForm from "./TradeForm";
import "../styles/Hero.css";
import PricingImg from "./PricingImg";
import Loader from "./Loader";
import { AppContext } from "../util/Manager";

function Hero({ props }) {
  const { ids } = props.match.params;
  const appContext = useContext(AppContext);

  return (
    <div id="hero">
      <div className="container">
        <div className="row">
          <TradeForm name={ids} />
          <PricingImg />
          <Loader isVisible={appContext.loading} />
        </div>
      </div>
    </div>
  );
}

export default Hero;
