import React from "react";
import { Carousel } from "react-responsive-carousel";
import TestimonialCard from "./TestimonialCard";
import "../styles/Testimonials.css";
import { fiveStars } from "../config/images.js";
import { isMobile } from "react-device-detect";

function Testimonials() {
  const testimonialData = [
    {
      testiTitle: `"Premium company"`,
      testiContent: `"24/7 customer service. You can talk to a human agent. They are quick to respond to any questions or concerns. Clients are treated in a very personal but professional way. Deposits and withdrawals are done instantly. Partners' and Masterpartners' com missions are always paid on time with a very simple transaction."`,
      testiStars: fiveStars,
      testiName: "Laura Verrone",
    },
    {
      testiTitle: `"Trustworthy broker"`,
      testiContent: `"As a client/ trader i can certainly recommend TIOmarkets. They have been around for a year just celebrating their 1st birthday and growing at a fast pace. As far as i know they are the only broker out there you can actually invest in. Earn passive income and trade FX - a nice combination."`,
      testiStars: fiveStars,
      testiName: "Veiko Annok",
    },
    {
      testiTitle: `"This broker is providing tight spreads."`,
      testiContent: `"This broker is providing tight spreads with very low commissions, amazing user interface, quick deposits and withdrawals. I recommend TIOmarkets."`,
      testiStars: fiveStars,
      testiName: "Nitin Khatri",
    },
    {
      testiTitle: `"I have had a positive experience with TIOmarkets so far."`,
      testiContent: `"It was very easy to set up, they talk you through it over the phone. They give regular updates about what’s happening/upcoming events that might affect the market. I love how their website shows you your performance, trade distribution and opening hours for trade sessions! Competitive spreads and a little bonus too!"`,
      testiStars: fiveStars,
      testiName: "Charity Sims",
    },
    {
      testiTitle: `"TIOmarkets is a very good broker."`,
      testiContent: `"I am highly satisfied from this broker and all their employees. Live chat officers are very helpful and sincere with all clients. Withdrawal is very fast and my account manager is very loyal and sincere with me and also helpful."`,
      testiStars: fiveStars,
      testiName: "Mohsin Ali Abbasi",
    },
    {
      testiTitle: `"I'm new to this broker."`,
      testiContent: `"I'm new to this broker but so far my account manager most especially has been very helpful, resourceful and swift with responses to all my needs. I would definitely recommend TIOMarkets to my friends and family members."`,
      testiStars: fiveStars,
      testiName: "Samaro Abri",
    },
    {
      testiTitle: `"Tio Markets is a very professional platform."`,
      testiContent: `"They have a very well informed-team to backup their profession. The team takes personal interest in each and every client and makes sure that they are comfortably trading and not losing money. Their spreads are low, that is how a trader saves money and makes more money."`,
      testiStars: fiveStars,
      testiName: "Hussain Hyderali",
    },
  ];
  return (
    <div id="testimonials">
      <div className="container">
        <div className="row">
          <div className="col-lg-12">
            <h1>Testimonials</h1>
            <p className="contentT">
              True, independent reviews of our products and services from
              trustpilot.com
            </p>
          </div>
          <div className="col-lg-12">
            <Carousel
              axis="horizontal"
              infiniteLoop
              centerMode={false}
              showArrows={!isMobile}
              showStatus={false}
              showThumbs={false}
              showIndicators={true}
              autoPlay={false}
              renderIndicator={(clickEvent, selected) => (
                <div
                  style={{
                    position: "relative",
                    top: "10px",
                    background: selected ? "#1259ff" : "rgba(0,0,0,0.2)",
                    borderColor: selected ? "#fff" : "#111",
                    borderWidth: "2px",
                    width: "10px",
                    height: "10px",
                    borderRadius: "5px",
                    display: "inline-flex",
                    margin: "5px",
                  }}
                  onClick={clickEvent}
                ></div>
              )}
            >
              {testimonialData.map((item) => (
                <TestimonialCard
                  key={item.testiTitle}
                  testiTitle={item.testiTitle}
                  testiContent={item.testiContent}
                  testiStars={item.testiStars}
                  testiName={item.testiName}
                />
              ))}
            </Carousel>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Testimonials;
