import React from "react";
import { MobilePrices, Apple, Dollar } from "../config/images";

function PricingImg() {
  return (
    <div className="col-lg-6 hflex">
      <div className="imgbox">
        <img src={MobilePrices} className="img-fluid mx-auto heroimg" alt="" />
        <img src={Apple} className="img-fluid mx-auto appleimg" alt="" />
        <img src={Dollar} className="img-fluid mx-auto dollarimg" alt="" />
      </div>
    </div>
  );
}

export default PricingImg;
