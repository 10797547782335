import React, { useEffect, useState, useRef, useContext } from "react";
import { Formik, Field } from "formik";
import * as Yup from "yup";
import "yup-phone";
import axios from "axios";

import "../styles/TradeForm.css";
import { countryDataCodes } from "../config/countryCodes";
import { getCurrency } from "../utils/helpers";
import { AppContext } from "../util/Manager";

function TradeForm({ name }) {
  const appContext = useContext(AppContext);

  const [introducer, setIntroducer] = useState("");
  const [firstStepDone, setFirstStepDone] = useState(false);
  const [country, setCountry] = useState();
  const [leadValues, setLeadValues] = useState();
  const [passwordError, setPasswordError] = useState();
  const [ip, setIp] = useState();
  const [apiErrors, setApiErrors] = useState();
  const [newPasswordError, setNewPasswordError] = useState();
  const [formHide, setFormHide] = useState(true);

  const [utms, setUtms] = useState({
    utm_campaign: "tiofriends",
    utm_source: "0",
    utm_term: "0",
    utm_medium: "0",
    utm_content: "0",
  });

  const countryRef = useRef();
  const phoneCodeRef = useRef();
  const phoneNumberRef = useRef();

  const validationSchema1 = Yup.object().shape({
    firstName: Yup.string().min(3).label("First name").required(),
    lastName: Yup.string().min(3).label("Last name").required(),
    email: Yup.string().label("Email").required().email(),
  });

  const validationSchema2 = Yup.object().shape({
    phoneNumber: Yup.string()
      .phone("", false, "Invalid Phone number")
      .required()
      .label("")
      .matches(
        /^\+((\\+[1-9]{1,4}[ \\-]*)|(\\([0-9]{2,3}\\)[ \\-]*)|([0-9]{2,4})[ \\-]*)*?[0-9]{3,4}?[ \\-]*[0-9]{3,4}?$/,
        "Invalid Phone number"
      ),
    password: Yup.string()
      .matches(
        /(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[^A-Za-z0-9])(?=.{8,})/,
        "8 to 15 characters in length, at least 1 uppercase letter, 1 lowercase letter, and 1 number. Optional special characters accepted: @!#$%^&*()-_./<>*:;|+=[]{}~"
      )
      .label("Password")
      .required(),
  });

  useEffect(() => {
    if (name) {
      const introducerApi = process.env.REACT_APP_TIOFRIENDS_GET_INTRODUCER;
      axios
        .post(introducerApi, {
          ref_code: name,
        })
        .then((response) => {
          if (response.data !== "No user found with ref_code " + name) {
            setIntroducer(response.data + " invited you to");
          } else {
            setIntroducer(
              "No introducer found, please verify you have a valid referral link."
            );
          }
        })
        .catch((err) => {
          console.log(err);
        });
    }
  }, [name]);

  useEffect(() => {
    const fetchAPI = process.env.REACT_APP_FETCH_IP_API;
    fetch(fetchAPI)
      .then((res) => res.json())
      .then((response) => {
        const { countryCode } = response;
        const { query } = response;

        setIp(query);
        setCountry(countryCode.toLowerCase());
      })
      .catch((data, status) => {
        console.log("Request IP Detection failed:", data);
      });
  }, [country]);

  useEffect(() => {
    const parameters = new URL(document.location).searchParams;
    if (parameters.toString() !== "") {
      parameters.forEach((el, key) => {
        utms[key] = el;
        setUtms({ ...utms });
      });
    }
  }, [utms]);

  const submitLead = (values) => {
    setFirstStepDone(true);
    console.log(values);
    setLeadValues(values);
    const hbsptLeadApi = process.env.REACT_APP_HBSPT_API_LEAD;
    axios
      .post(hbsptLeadApi, {
        submittedAt: Date.now(),
        fields: [
          {
            name: "firstname",
            value: values.firstName,
          },
          {
            name: "lastname",
            value: values.lastName,
          },
          {
            name: "email",
            value: values.email,
          },
          {
            name: "country",
            value: values.country,
          },
          {
            name: "browser_lang",
            value: "en",
          },
          {
            name: "hsid",
            value: "",
          },
          {
            name: "tio_register",
            value: "",
          },
          {
            name: "pref_comm_lang",
            value: "en",
          },
          {
            name: "utm_campaign",
            value: utms.utm_campaign,
          },
          {
            name: "utm_source",
            value: utms.utm_source,
          },
          {
            name: "utm_medium",
            value: utms.utm_medium,
          },
          {
            name: "utm_content",
            value: utms.utm_content,
          },
          {
            name: "utm_term",
            value: utms.utm_term,
          },
        ],
        context: {
          pageUri: "https://tiofriends.com",
          pageName: "TIOfriends LP",
        },
        skipValidation: true,
      })
      .then((response) => {
        console.log("Submit Lead Success:", response.data);
        appContext.setEmail(values.email);
        setApiErrors();
      })
      .catch((err) => {
        setApiErrors(err);
        console.log("Submit Lead error:", err);
      });
  };

  const submitPendingClient = (values) => {
    const dateTimeUnix = Date.now().toString();
    //Hubspot Endpoint
    const hbsptEndpoint = process.env.REACT_APP_HBST_API_PC;
    //TIOFriends Endpoint
    const invitationEndpoint =
      process.env.REACT_APP_TIOFRIENDS_CREATE_INVITATION;

    axios
      .post(invitationEndpoint, {
        ref_code: name ?? "",
        tiofriend_registration_date: dateTimeUnix,
        fname: leadValues.firstName,
        tiofriend_name: leadValues.firstName + " " + leadValues.lastName,
        lname: leadValues.lastName,
        email: leadValues.email,
        country: leadValues.country.toUpperCase(),
        realcountry: leadValues.country.toUpperCase(),
        phone: values.phoneNumber,
        initial_currency_code: getCurrency(leadValues.country),
        new_password: values.password,
        confirm_password: values.password,
        ip_reg: ip,
        lang: "en",
        click_time: dateTimeUnix,
        utm_campaign: utms.utm_campaign,
        utm_medium: utms.utm_medium,
        utm_source: utms.utm_source,
        utm_content: utms.utm_content,
        utm_term: utms.utm_term,
        pref_comm_lang: "en",
      })
      .then((response) => {
        // If Success make Hubspot Call
        const data = response.data;

        if (data === "Invitation successfully added!") {
          axios
            .post(hbsptEndpoint, {
              submittedAt: Date.now(),
              fields: [
                {
                  name: "firstname",
                  value: leadValues.firstName,
                },
                {
                  name: "lastname",
                  value: leadValues.lastName,
                },
                {
                  name: "email",
                  value: leadValues.email,
                },
                {
                  name: "country",
                  value: leadValues.country,
                },
                {
                  name: "account_currency",
                  value: getCurrency(leadValues.country),
                },
                {
                  name: "main_reg",
                  value: "yes new",
                },
                {
                  name: "npoptin",
                  value: "1",
                },
                {
                  name: "regulation_entity",
                  value: "TIOSV",
                },
                {
                  name: "phone",
                  value: values.phoneNumber,
                },
                {
                  name: "browser_lang",
                  value: "en",
                },
                {
                  name: "hsid",
                  value: "",
                },
                {
                  name: "tio_register",
                  value: "",
                },
                {
                  name: "pref_comm_lang",
                  value: "en",
                },
                {
                  name: "utm_campaign",
                  value: utms.utm_campaign,
                },
                {
                  name: "utm_source",
                  value: utms.utm_source,
                },
                {
                  name: "utm_medium",
                  value: utms.utm_medium,
                },
                {
                  name: "utm_content",
                  value: utms.utm_content,
                },
                {
                  name: "utm_term",
                  value: utms.utm_term,
                },
              ],
              context: {
                pageUri: "https://tiofriends.com",
                pageName: "TIOfriends LP",
              },
              skipValidation: true,
            })
            .then((response) => {
              const hbstData = response.data;

              console.log(hbstData);
              appContext.setModalHide(true);
              appContext.setLoading(false);

              setFormHide(false);
              setApiErrors();
            })
            .catch((err) => {
              setApiErrors(err.message);
              console.log("Pending Client HBSPT error", err);
              appContext.setLoading(false);
            });
        } else {
          if (data === "User exists as verified in NP.") {
            setNewPasswordError(true);
          } else {
            // setApiErrors(
            //   "Your email address already exists within our system."
            // );
            setNewPasswordError(true);
          }
          appContext.setLoading(false);
        }
        console.log("Pending Client", data);
      })
      .catch((err) => {
        appContext.setLoading(false);
        setApiErrors(err.message);
        console.log("Pending Client Error", err);
      });
  };

  return (
    <div id="tradeform" className="col-lg-6">
      {introducer !== "" && <h2>{introducer} </h2>}
      <h1>Trade Forex & Stocks online</h1>
      <p>From $0 commissions per lot & 0.2 pips on EURUSD</p>
      {!firstStepDone && (
        <Formik
          validationSchema={validationSchema1}
          // validateOnBlur={true}
          initialValues={{
            firstName: "",
            lastName: "",
            email: "",
            country: country,
          }}
          onSubmit={(values) => {
            submitLead(values);
          }}
        >
          {({
            values,
            errors,
            touched,
            handleChange,
            handleBlur,
            handleSubmit,
            isSubmitting,
            /* and other goodies */
          }) => (
            <form className="formflex" onSubmit={handleSubmit}>
              <input
                type="text"
                name="firstName"
                placeholder="First Name"
                onChange={handleChange}
              />
              {touched.firstName && (
                <p className="validationError">{errors.firstName}</p>
              )}
              <input
                type="text"
                name="lastName"
                placeholder="Last Name"
                onChange={handleChange}
              />
              {touched.lastName && (
                <p className="validationError">{errors.lastName}</p>
              )}
              <input
                type="email"
                name="email"
                placeholder="Email"
                onChange={handleChange}
                onSubmit={handleSubmit}
              />
              {touched.email && (
                <p className="validationError">{errors.email}</p>
              )}

              <select
                ref={countryRef}
                name="country"
                onChange={handleChange}
                value={values.country}
              >
                {countryDataCodes.map((el, idx) => (
                  <option value={el.iso2} key={idx}>
                    {el.name}
                  </option>
                ))}
              </select>

              <div className="nextbtn">
                <input
                  className="submitInput left"
                  value="Next"
                  type="button"
                  onSubmit={handleSubmit}
                  onClick={handleSubmit}
                  // tabIndex="-1"
                />
              </div>
            </form>
          )}
        </Formik>
      )}

      {formHide && firstStepDone && (
        <Formik
          validationSchema={validationSchema2}
          validateOnBlur={true}
          // validateOnChange={true}
          initialValues={{
            phone: "",
            password: "",
            passwordConfirm: "",
            phoneCode: countryDataCodes.find((el, idx) => {
              return el.iso2 === country;
            }).dialCode,
            phoneNumber:
              "+" +
              countryDataCodes.find((el, idx) => {
                return el.iso2 === country;
              }).dialCode,
          }}
          onSubmit={(values) => {
            appContext.setLoading(true);
            if (values.password === values.passwordConfirm) {
              submitPendingClient(values);
              console.log(values);
              setPasswordError();
            } else {
              setPasswordError("Passwords do not match");
              appContext.setLoading(false);
              console.log("Passwords do not match");
            }
          }}
        >
          {({
            values,
            errors,
            touched,
            handleChange,
            handleBlur,
            handleSubmit,
            setFieldValue,
          }) => (
            <form className="formflex" onSubmit={handleSubmit}>
              <div className="telephone">
                <select
                  ref={phoneCodeRef}
                  name="phoneCode"
                  value={values.phoneCode}
                  onChange={(e) => {
                    setFieldValue("phoneCode", e.target.value);
                    handleChange("phoneCode");
                    setFieldValue(
                      "phoneNumber",
                      "+" + e.target.value + values.phone
                    );
                  }}
                >
                  {countryDataCodes.map((el, idx) => (
                    <option value={el.dialCode} key={idx}>
                      {`+${el.dialCode}`}
                    </option>
                  ))}
                </select>
                <input
                  ref={phoneNumberRef}
                  type="tel"
                  name="phone"
                  placeholder="Phone Number"
                  onChange={(e) => {
                    handleChange("phoneNumber");
                    handleChange("phone");
                    setFieldValue("phone", e.target.value);
                    setFieldValue(
                      "phoneNumber",
                      "+" + values.phoneCode + e.target.value
                    );
                  }}
                />
                <Field name="phoneNumber" type="hidden" />
              </div>
              <p className="validationError"> {errors.phoneNumber}</p>
              <input
                type="password"
                name="password"
                placeholder="Password"
                onChange={handleChange}
                onBlur={handleBlur}
                value={values.password}
              />
              {touched.password && (
                <p className="validationError"> {errors.password}</p>
              )}

              <input
                type="password"
                name="passwordConfirm"
                placeholder="Confirm Password"
                onChange={(e) => {
                  setFieldValue("passwordConfirm", e.target.value);
                  if (e.target.value === values.password) {
                    setPasswordError();
                  } else {
                    setPasswordError("Passwords do not match");
                  }
                }}
                onBlur={handleBlur}
                value={values.passwordConfirm}
              />
              {passwordError && (
                <p className="validationError"> {passwordError}</p>
              )}
              <div className="nextbtn">
                <button
                  className="backBtn"
                  onClick={() => {
                    setFirstStepDone(false);
                  }}
                >
                  Back
                </button>
                <div className="errorMessage">
                  <input
                    className="submitInput"
                    type="button"
                    onSubmit={handleSubmit}
                    onClick={handleSubmit}
                    value="Submit"
                  />
                </div>
              </div>
              {apiErrors && (
                <p className="errorMsg">{JSON.stringify(apiErrors)}</p>
              )}
              {newPasswordError && (
                <p className="errorMsg">
                  Your email address already exists within our system. Please
                  click to{" "}
                  <a href="https://clients.tiomarkets.com/login#forgotpassword">
                    reset your password
                  </a>{" "}
                  or try again with a new email address.
                </p>
              )}
            </form>
          )}
        </Formik>
      )}
    </div>
  );
}

export default TradeForm;
