import React, { useState } from "react";
import { Document, Page, pdfjs } from "react-pdf";
import "../privacyPolicy.css";
import Pdf from "../documents/TIOfriends_PrivacyPolicy.pdf";

const PrivacyPolicy = () => {
  pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`;
  // eslint-disable-next-line
  const [numPages, setNumPages] = useState(null);
  const [pageNumber, setPageNumber] = useState(1);

  function onDocumentLoadSuccess({ numPages }) {
    setNumPages(numPages);
  }

  return (
    <div
      style={{
        margin: "auto",
        alignSelf: "center",
        justifyContent: "center",
        alignItems: "center",
        width: "100vw",
        height: "100vh",
      }}
    >
      <Document
        file={Pdf}
        onLoadSuccess={onDocumentLoadSuccess}
        className="pdfContainer"
        renderMode="svg"
      >
        <Page pageNumber={pageNumber} />
        <div
          className="page-controls"
          style={{
            opacity: 1,
          }}
        >
          <button
            type="button"
            onClick={() => {
              if (pageNumber > 1) {
                setPageNumber(pageNumber - 1);
              }
            }}
          >
            ‹
          </button>
          <span>{pageNumber} of 4</span>
          <button
            type="button"
            onClick={() => {
              if (pageNumber < 4) {
                setPageNumber(pageNumber + 1);
              }
            }}
          >
            ›
          </button>
        </div>
      </Document>
    </div>
  );
};

export default PrivacyPolicy;
